<template>
  <li class="folder-tree__list">
    <div class="folder-tree__item folder-tree__root folder-tree__link-container" :keyid="folder.id">
      <router-link v-if="folderSelectionEnabled && !editMode"
        :to="{ name: 'folderview', params: { order: orderId, folder: folder.id } }" class="folder-tree__link router-link"        
        draggable="false"
        @drop="onDrop" @dragover="onDragOver" @dragleave="onDragLeave" @dragenter="onDragEnter"
        :class="dropStatus"
        :keyId="folder.id">
        <img src="../assets/icons/folder.svg" class="custom-svgs folder-tree__link-icon" alt="" />
        <span class="folder-name">
          {{ folder.name }}
        </span>
      </router-link>
      <span v-else class="folder-tree__link">
        <img v-if="!editMode" src="../assets/icons/folder.svg" class="custom-svgs folder-tree__link-icon" alt="" />
        <label class="checkbox__input-container checkbox-in-tree__container">
          <input type="checkbox" v-if="editMode" @change="selectForEditing($event)" />
          <span class="folder-name">
            {{ folder.name }}
          </span>
          <span v-if="editMode" class="checkbox__checkmark checkbox-in-tree__checkmark"></span>
        </label>
      </span>
    </div>
    <ul v-if="folder.markings.length" class="folder-tree__markings">
      <li v-for="(marking, index) in folder.markings" :key="marking.id" >
        <marking-tree-view :marking="marking" :index="index" :route="markingRoute" />
      </li>
    </ul>
    <ul v-if="folder.folders.length" class="folder-tree__folders">
      <folder-tree-view v-for="f in folder.folders" :key="f.id" :folder="f"
        :folder-selection-enabled="folderSelectionEnabled" :folder-edit-enabled="true" :marking-route="markingRoute" />
    </ul>
  </li>
</template>

<script src="./FolderTreeView.ts" lang="ts"></script>

<style scoped lang="scss">
  @keyframes drop-allowed {
    0% {
      border-color: $switch-on;
      box-shadow: inset 0 0 0px 1px $switch-on;
    }
    50% {
      border-color: transparent;
      box-shadow: inset 0 0 0px 1px transparent;
    }
    100% {
      border-color: $switch-on;
      box-shadow: inset 0 0 0px 1px $switch-on;
    }
  }

  .folder-tree__link.allowed {
    animation: drop-allowed 1.5s linear infinite;

    * {
      // Disallow drag leave event to trigger when a child element is encountered
      pointer-events: none;
    }
  }

  .folder-name {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: text-top;
  }
</style>
