import axios from "./index";
import { CreateMarking, Marking, MarkingImage } from "@/models/ordermodels";

export const MarkingClient = {
    async createMarking(model: CreateMarking): Promise<Marking> {
        return await axios.post("/api/app/marking", model);
    },
    async changeMarking(model: Marking): Promise<void> {
        return await axios.patch("/api/app/marking", model);
    },
    async updateMarking(model: Marking): Promise<void> {
        return await axios.put("/api/app/marking", model);
    },
    async updateMarkingName(orderId: string, id: string, name: string): Promise<void> {
      return await axios.put("/api/app/marking/name", {orderId: orderId, id: id, name: name});
    },
    async getImages(id: string): Promise<MarkingImage[]> {
        return await axios.get("/api/app/marking/images/" + id);
    },
    async createImage(data: FormData): Promise<void> {
        return await axios.post('/api/app/marking/image', data);
    },
    async updateImage(model: MarkingImage): Promise<void> {
        return await axios.put("/api/app/marking/image", model);
    },
    async deleteImage(id: string): Promise<void> {
        return await axios.delete("/api/app/marking/" + id + "/image");
    },
    async deleteMarking(markingId: string, throwIfNull: boolean = true): Promise<void> {
        return await axios.delete("/api/app/marking", {
            params: {
                markingId,
                throwIfNull
            }
        });
    }
};
