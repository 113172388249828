import { computed, defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { Marking, Order } from "../models/ordermodels";
import { OrderStore } from "../store/orderstate";
import MarkingTile from "./MarkingTile.vue";
import { ChangeStore } from "../store/changestate";
import { UIStore } from "../store/ui";
import { MarkingClient } from "../axios/markingclient";
import { OrderService } from "../services/OrderService";

export default defineComponent({
    name: "MarkingTreeView",
    components: {
        MarkingTile,
    },
    props: {
        marking: {
            type: Object as () => Marking,
            required: true
        },
        route: {
            type: String,
            required: true
        },
        index: {            
            type: Number,
            required: true
        }
    },
    data() {
        return {
            openPreview: false
        }
    },
    methods: {
        handleCloseMarkingPreview() {
            this.openPreview = !this.openPreview;
        }
    },
    setup(props) {
        const order = computed<Order>(() => OrderStore.order as Order);
        const marking = reactive(props.marking);
        const editMode = computed<boolean>(() => ChangeStore.editMode);
        const router = useRouter();
        const isMoving = ref(false);
        const ghostStyle = ref<{ top: string; left: string }>({
            top: '0px',
            left: '0px'
        });

        const selectForEditing = async (e: InputEvent): Promise<void> => {
            const target = (e.target as HTMLInputElement);
            await ChangeStore.saveMarkingState({ id: marking.id, checked: target.checked, name: marking.name });
        };

        const validDropTarget = (event: DragEvent): boolean => {
            return event.dataTransfer != null
                && event.dataTransfer.types.includes("move-marking")                
                && !event.dataTransfer.types.includes("same-move-marking-" + marking.id);
        };

        const onTouchStart = (event: TouchEvent) => {
            isMoving.value = true;
            onDragStart(event);
        }

        const onDragStart = (event: DragEvent  | TouchEvent) => {
            const dragEvent = event as DragEvent;
            if (dragEvent.dataTransfer != null) {
                dragEvent.dataTransfer.clearData();
                dragEvent.dataTransfer.dropEffect = "move";
                dragEvent.dataTransfer.effectAllowed = "move";
                dragEvent.dataTransfer.setData("move-marking-from-folder-" + marking.folderId, "");
                dragEvent.dataTransfer.setData("move-marking", marking.id);
                dragEvent.dataTransfer.setData("same-move-marking-" + marking.id, "marking.id");  
                dragEvent.dataTransfer.setData("same-move-index-" + props.index, "marking.id");           
            }
        };        

        const onDrop = async (event: DragEvent): Promise<void> => {
            if (validDropTarget(event)) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                UIStore.setLoading(true);
                const markingId = event.dataTransfer?.getData("move-marking") ?? "";
                const oldIndex = event.dataTransfer?.getData("same-move-index-") ?? "";
                await MarkingClient.changeMarking({
                    id: markingId,
                    folderId: marking.folderId,
                    newIndex: props.index,
                    oldIndex: Number(oldIndex),
                    markOnMarkPosChange: true,
                } as Marking);
                await OrderService.loadOrder(order.value.id);
                await ChangeStore.clearState();
                router.push({ name: "order", params: { orderId: order.value.id } });
                UIStore.setLoading(false);
                event.preventDefault();
            }
        };

        const onDragOver = (event: DragEvent | TouchEvent): void => {
            event.preventDefault();
        };

        const onTouchEnd = async (event: TouchEvent): Promise<void> => { 
            isMoving.value = false;    
            ghostStyle.value.top = `${0}px`;
            ghostStyle.value.left = `${0}px`;            
            
            const touchEndElement = document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY) as HTMLElement | null;
            const folderId = getAttributeFunc(touchEndElement, "keyId");
            const newIndex = getAttributeFunc(touchEndElement, "markingIndex");

            const target = event.target as HTMLElement | null;
            const oldIndex = getAttributeFunc(target, "markingIndex");
            const markingId = getAttributeFunc(target, "markingKeyId");

            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            UIStore.setLoading(true);
            patch(folderId, markingId, Number(newIndex), Number(oldIndex));
            UIStore.setLoading(false);                
        };

        const getAttributeFunc = (element: HTMLElement | null, id: string) => {
            let targetFolderId = element?.getAttribute(id);
            let targetParentFolderId = element?.parentElement?.getAttribute(id);

            if (!targetFolderId && !targetParentFolderId) {
                // Check if element or any of its parents has the class "folder-tree__link router-link"
                const targetElement = element?.closest(".folder-tree__link.router-link");
                const parentTargetElement = element?.parentElement?.closest(".folder-tree__link.router-link");
            
                targetFolderId = targetElement?.getAttribute(id);
                targetParentFolderId = parentTargetElement?.getAttribute(id);
            }

            return targetFolderId ? targetFolderId : targetParentFolderId;
        }

        const onTouchMove = (event: TouchEvent) => {
            const touch = event.touches[0];

            const ghostOffsetX = 140;
            const ghostOffsetY = 50;

            ghostStyle.value.top = `${touch.clientY - ghostOffsetY}px`;
            ghostStyle.value.left = `${touch.clientX - ghostOffsetX}px`;
        }

        const patch = async (folderId: string | null | undefined, markingId: string | null | undefined, newIndex: number | null | undefined, oldIndex: number | null | undefined): Promise<void> => {            
            await MarkingClient.changeMarking({
                id: markingId,
                folderId: folderId,
                newIndex: newIndex,
                oldIndex: oldIndex,
                markOnMarkPosChange: true
            } as Marking);
            await OrderService.loadOrder(order.value.id);
            await ChangeStore.clearState();
            router.push({ name: "order", params: { orderId: order.value.id } });
        }
                

        return {
            order,
            editMode,
            selectForEditing,
            onDragStart,
            onDrop,
            onDragOver,
            onTouchEnd,
            onTouchStart,
            isMoving,
            onTouchMove,
            ghostStyle
        };
    }
});
