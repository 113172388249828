<template>
<div class="ghost-item-container" :style="ghostStyle" @click.prevent @touch.prevent>
  <div v-if="isMoving" class="ghost-item" @click.prevent @touch.prevent>
    <img src="../assets/icons/marking.svg" class="custom-svgs folder-tree__link-icon" alt="" />
    <span class="marking-name">
      {{ marking.name }}
    </span>
  </div>
</div>
  <div class="folder-tree__item" :class="{ 'moving': isMoving }">
    <div class="folder-tree__item-container" :class="
      openPreview
        ? 'folder-tree__item-container--preview-open'
        : ''
    ">
      <div class="folder-tree__link-container" :keyid="marking.folderId" :markingKeyId="marking.id" :markingIndex="$props.index">
        <div v-if="editMode" class="folder-tree__link">
          <label class="checkbox__input-container checkbox-in-tree__container">
            <input type="checkbox" @change="selectForEditing($event)" /> {{ marking.name }}
            <span class="checkbox__checkmark checkbox-in-tree__checkmark"></span>
          </label>
        </div>
        <router-link v-if="!editMode"
            :keyid="marking.folderId" :markingKeyId="marking.id" :markingIndex="$props.index"
            draggable="true"
            @dragstart="onDragStart" @drop="onDrop" @dragover="onDragOver"
            @touchstart="onTouchStart" @touchend="onTouchEnd" @touchmove="onTouchMove"
            class="folder-tree__link marking"
            :to="{ name: route, params: { order: order.id, marking: marking.id } }">
          <img src="../assets/icons/marking.svg" class="custom-svgs folder-tree__link-icon" alt="" />
          <span class="marking-name">
            {{ marking.name }}
          </span>
          <span class="grabber" @click.prevent @touch.prevent>≣</span>           
        </router-link>
        <div @click="handleCloseMarkingPreview" class="folder-tree__toggle-preview-container">
          <svg class="custom-svgs">
            <use xlink:href="../assets/icons/sprite.svg#chevron-left"></use>
          </svg>
        </div>
      </div>
      <div class="folder-tree__preview-container">
        <marking-tile :marking="marking" />
      </div>
    </div>
  </div>
</template>

<script src="./MarkingTreeView.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./MarkingTreeView.scss";
</style>
