import { defineComponent, computed, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { MarkingClient } from "@/axios/markingclient";
import { Folder, Marking, Order } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import MarkingTreeView from "./MarkingTreeView.vue";
import { ChangeStore } from "@/store/changestate";
import { OrderService } from "@/services/OrderService";
import { UIStore } from "@/store/ui";

enum DropStatus {
    unknown = "",
    allowed = "allowed",
    disallowed = "disallowed",
}

export default defineComponent({

    name: "FolderTreeView",

    components: {
        MarkingTreeView
    },

    props: {
        folder: {
            type: Object as () => Folder,
            required: true,
        },
        folderSelectionEnabled: {
            type: Boolean,
            required: false,
        },
        folderEditEnabled: {
            type: Boolean,
            required: false,
        },
        markingRoute: {
            type: String,
            required: true,
        },
    },
    methods: {
    },
    setup(props) {
        const folder = reactive(props.folder);
        const order = reactive<Order>(OrderStore.order as Order);
        const editMode = computed<boolean>(() => ChangeStore.editMode && folder.parentId !== null);
        const router = useRouter();
        const dropStatus = ref(DropStatus.unknown);

        const selectForEditing = async (e: InputEvent): Promise<void> => {
            const target = (e.target as HTMLInputElement);
            await ChangeStore.saveFolderState({ id: folder.id, checked: target.checked, name: folder.name });
        };

        const validDropTarget = (event: DragEvent): boolean => {
            return event.dataTransfer != null
                && event.dataTransfer.types.includes("move-marking")
                && !event.dataTransfer.types.includes("move-marking-from-folder-" + folder.id);
        };

        const onDrop = async (event: DragEvent): Promise<void> => {
            if (validDropTarget(event)) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                const markingId = event.dataTransfer?.getData("move-marking") ?? "";
                const marking = order.markings.get(markingId) as Marking;
                const sourceFolder = order.folders.get(marking.folderId) as Folder;
                dropStatus.value = DropStatus.unknown;
                sourceFolder.markings = sourceFolder.markings.filter(x => x.id !== marking.id);
                const markings = folder.markings;
                marking.folderId = folder.id;
                markings.push(marking);
                markings.sort((x, y) => x.name.localeCompare(y.name));
                folder.markings = markings;
                UIStore.setLoading(true);
                await MarkingClient.changeMarking({
                    id: marking.id,
                    folderId: folder.id,
                    markOnMarkPosChange: false
                } as Marking);
                await OrderService.loadOrder(order.id);
                await ChangeStore.clearState();
                router.push({ name: "order", params: { orderId: order.id } });
                UIStore.setLoading(false);
            }
        };

        const onDragOver = (event: DragEvent): void => {
            if (validDropTarget(event)) {                
                event.preventDefault();    
            }
        };

        const onDragEnter = (event: DragEvent): void => {
            if (validDropTarget(event)) {
                dropStatus.value = DropStatus.allowed;
            } else {
                dropStatus.value = DropStatus.disallowed;
            }
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const onDragLeave = (event: DragEvent): void => {            
            if (validDropTarget(event)) {
                dropStatus.value = DropStatus.unknown;
            }
        };

        return {
            orderId: order.id,
            editMode,
            selectForEditing,
            onDragEnter,
            onDragLeave,
            onDragOver,
            onDrop,
            dropStatus,
            DropStatus
        };
    }
});
