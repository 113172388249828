import { CalculateCurtainDropsApiOutput } from "./configuratormodels";

export interface Order {
    id: string;
    offerName: string;
    offerNumber: number;
    customerOrderNumber: string;
    creationTimeFormatted: string;
    offerSentToCustomerAtFormatted: string;
    note: string;
    userEmailAddress: string;
    orderTotalExclVat: Price;
    orderTotalDiscountAmount: Price;
    orderTotalNetAmount: Price;
    orderTotalInclVatAndFreightCost: Price;
    orderVat: Price;
    freightCost: Price;
    ecomBasketId: string;
    currencyCode: string;
    status: OrderStatus;
    folder: Folder;
    folders: Map<string, Folder>;
    markings: Map<string, Marking>;
    orderLines: OrderLine[];
    fabricMarkingList: FabricMarkingGroup[];
    isNewDeductionType : string;
};

export interface OrderLine {
    ecomProductId: number;
    ecomRowId: number | null;
    quantity: number | null;
    markingQuantity: number | null;
    markingId: string;
    markingName: string;
    folderName: string;
    erpConfigurableProductId: number;
    erpProductId: number | null;
    price: Price;
    recommendWeights: boolean;
    productName: string;
    productWidth: number;
    productRepeatHeight: number;
    curtainConfiguration: number;
    wallHeight: number;
    wallWidth: number | null;
    heightDeduction: number | null;
    useWeights: boolean;
    sewTogether: boolean;
    splitCurtain: boolean;
    curtainData: CalculateCurtainDropsApiOutput | null;
}

export interface Folder {
    id: string;
    name: string;
    parentId: string;
    orderId: string;
    folders: Folder[];
    markings: Marking[];
}

export interface Marking {
    id: string;
    name: string;
    folderId: string;
    images: MarkingImage[];
    productImage: string | undefined;
    newIndex: number | undefined;
    oldIndex: number | undefined;
    markOnMarkPosChange: boolean;
}

export type MarkingSummary = {
    curtainConfiguration: number,
    markingName: string;
    productName: string;
    sewingType: string;
    totalFinishedWidth: number;
    finishedHeight: number;
    price: Price;
    quantity: number;
    curtainDrops: CurtainDropSummary[];
    underHem: number;
}

export interface CurtainDropSummary {
    quantity: number;
    finishedWidthPerCurtainDrop: number;
    finishedHeightPerCurtainDrop: number;
}

export interface MarkingImage {
    id?: string;
    name: string;
    note: string;
    markingId: string;
    fileId: string,
    thumbnailFileId: string,
}

export interface CreateOrder {
    offerName: string;
};

export enum OrderStatus {
    Initiated = 0,
    OfferSentToCustomer = 1,
    OrderPlaced = 2,
    Failed = 3,
    Deleted = 4,
}

export type OrderByStatus = {
    [key in OrderStatus]: Order[];
}

export interface CreateMarking {
    name: string;
    folderId: string;
}

export interface CreateFolder {
    name: string;
    parentId: string | null;
    orderId: string;
}

export interface PlaceOrder {
    orderId: string;
    customerInformation: CustomerInformation;
}

export interface PlaceOrderResponse {
    status: string;
    statusDescription: string;
}

export interface CopyOrder {
    orderId: string;
    offerName: string;
}

export interface CopyItem {
  id: string;
  name: string;
}

export interface CustomerInformation {
    customersOrderNumber: string;
    requestedDeliveryDate: string;
    deliveryAddressCompanyName: string;
    address1: string;
    address2: string;
    zipcode: string;
    city: string;
    country: string;
    cellPhone: string;
}

export interface FabricMarkingGroup {
    productId: string;
    variantId: string;
    variantName: string;
    variantImage: string;
    markings: FabricMarking[];
}

export interface FabricMarking {
    folderId: string;
    folderName: string;
    markingId: string;
    markingName: string;
    markingQuantity: number;
    curtainData: CalculateCurtainDropsApiOutput;
}

export interface Price {
    value: number;
    formatted: string;
    currencyCode: string;
}

export interface SendOrderPdfResponse {
    isSuccess: boolean;
    message: string;
}
